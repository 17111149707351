// This page is specifically for the iOS and Android apps. It allows us to pull in a webview of the About page
// without the web specific UI. This page is omitted from the sitemap in gatsby-config.js

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CategoryHero from 'components/heros/categoryHero';
import SignUpCta from 'components/ctas/signUpCta';

const About = ({ data }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
    } },
  } = data;

  return (
    <React.Fragment>
      {/* classname is added to allow global override in appStyle.js */}
      <CategoryHero
        className="app-full-height-hero"
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image}
      />
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/about/" } }) {
      ...CategoryHeroFragment
    }
  }
`;

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;
